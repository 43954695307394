<script setup>
  const route = useRoute();
  const backend = useBackend();
  const storage = useSession();

  const uuid = route.params.uuid;
  const secret = route.params.secret;

  onBeforeMount(() => {
    backend.getDigitalOrder(uuid, secret).then(result => {
      if(result.data.error === true) {
        navigateTo('/', { redirectCode: 301 })
      }
      storage.setItem('__transaction', {
        ...storage.getItem('__transaction'),
        [uuid]: secret
      });
      navigateTo('/zamow/' + uuid, { redirectCode: 301 })
    })
  });

</script>

<template>

</template>
